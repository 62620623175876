import Vue from 'vue'
import VueGtag from 'vue-gtag'


const isProductionSite = (process.env.stage === 'prod')
if (isProductionSite) {
  console.log("production site, so initiatizing GA library and enabling GA as default.")
  Vue.use(VueGtag, {
    config: { id: 'G-9EYFRF6GED' },
    enabled: true // enable by default, disable if user selects no statistics cookie
  })
} else {
  console.log("NOT production site so NOT initiatizing GA")
}



