import axios from "axios";

export const state = () => ({
  currentRequest: 0,
  requests: []
})

export const getters = {
  getRequestById: (state) => (id) => {
    return state.requests.find(request => request.id === id)
  },
  getCurrent: (state) => {
    return state.requests.length ? state.requests[state.currentRequest] : {}
  },
}

export const mutations = {
  changeCurrent (state, index) {
    state.currentRequest = index
  },
  clearRequests (state) {
    state.requests = []
  },
  add (state) {
    state.requests.push({
      // id: (function b (a) { return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b) })()
    })
  },
  remove (state, { todo }) {
    state.requests.splice(state.requests.indexOf(todo), 1)
  },
  removeLast (state) {
    state.requests.splice(state.requests.length - 1, 1)
  },
  removeCurrent (state) {
    let cur = state.currentRequest
    state.currentRequest = cur ? cur - 1 : 0
    state.requests.splice(cur, 1)
  },
  update (state, {...args} = {}) {
    state.requests.splice(state.currentRequest, 1, Object.assign({}, state.requests[state.currentRequest], args))
  },
  toggle (state, todo) {
    todo.done = !todo.done
  },
  removeProperty (state, properties) {
    properties.forEach(element => delete state.requests[state.currentRequest][element])
  },
}

async function sendUpdateToBackend (newStatus, body) {
  delete body.status // remove any old thing that was there
  body.newStatus = newStatus
  // If there is an id set the backend will update the record with the body data,
  // if there is no id the backend will create a new record with the body data, and return a new id
  console.log("newStatus", newStatus)
  let id = null
  const isProductionSite = (process.env.stage === 'prod')
  console.log("in sendUpdateToBackend with isProductionSite: ", isProductionSite)
  await axios
    .post(
      `/api/ui-update-backend${isProductionSite ? '' : '/dev'}`,
      body,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    )
    .then(response => {
      console.log('Saved in the backend with id:', response.data.id)
      id = response.data.id
    })
    .catch(error => {
      console.log(error)
    })
  return id
}

export const actions = {
  recordAnalytics ({app}, payload) {
  //  console.log("recordAnalytics payload ", payload)
  //  this.app.$Analytics.record(payload.event, payload.attributes || {}, payload.metrics || {})
  },
  async updateBackendFormReviewed ({commit, state, rootState, app}) {
    console.log("in updateBackendFormReviewed")
    let responseId = await sendUpdateToBackend('formReviewed', state.requests[state.currentRequest]) // don't change this, backend code needs this exact string
    commit('update', {id: responseId})  // save the id, it will be sent with future backend updates
    commit('update', {status: 'formReviewed'})
  },
  async updateBackendFormDownloaded ({commit, state, rootState, app}) {
    console.log("in updateBackendFormDownloaded")
    let responseId = await sendUpdateToBackend('formDownloaded', state.requests[state.currentRequest]) // don't change this, backend code needs this exact string
    commit('update', {id: responseId})  // should already have the ID but resave just in case
    commit('update', {status: 'formDownloaded'})
  },
  async updateBackendFormEmailed ({commit, state, rootState, app}) {
    console.log("in updateBackendFormEmailed")
    let responseId = await sendUpdateToBackend('formEmailed', state.requests[state.currentRequest]) // don't change this, backend code needs this exact string
    commit('update', {id: responseId})  // should already have the ID but resave just in case
    commit('update', {status: 'formEmailed'})
  },
  addOrUpdate ({ commit, state }, {id, ...args} = {}) {
    if (!state.requests.findIndex(x => x.id === id) || !id) {
      // add request
      commit('add')
      id = state.requests[state.requests.length - 1].id
    }
    commit('update', ...args)
  },
  refresh ({ commit, state, rootState }) {
    commit('add')
    commit('removeLast')
    if (state.requests.length === 0) {
      commit('add')
      commit('update', {
        email: '',
        tel: ''
      })
    }
  },
  removeCurrent ({ commit, state, rootState }) {
    commit('removeCurrent')
    if (state.requests.length === 0) {
      commit('add')
      commit('update', {
        country: rootState.userdata.session.country
      })
    }
  }
}
