import { butter } from '~/plugins/buttercms'
import { STATE_ISO_CODE } from '~/utils/VFAParameters'
import VuexPersistence from 'vuex-persist'
import { Toast } from 'buefy'
// LoadingProgrammatic, ModalProgrammatic, also available from buefy

let vuexLocal

const vuexLocalPlugin = (store) => {
  // called when the store is initialized
  if (process.browser) {
    window.onNuxtReady((nuxt) => {
      vuexLocal = new VuexPersistence({
        strictMode: true,
        storage: window.sessionStorage
      })
      return vuexLocal.plugin(store)
    })
  }
  store.subscribe((mutation) => {
    if (mutation.type === 'RESTORE_MUTATION') {
      store.dispatch('requests/refresh')
      if (!window.location.hash) {
        store.dispatch('userdata/getUser')
      }
      store.dispatch('userdata/setSession')
      store.dispatch('retrieveButterCollectionsAsync')
    }
  })

  if (!sessionStorage.getItem("timeWarpOffset")) {
    sessionStorage.setItem("timeWarpOffset", 0)
  }
}

export const plugins = [vuexLocalPlugin]

export const state = () => ({
  cookieConsent: {
    necessary: true,
    preferences: true,
    statistics: true,
    marketing: false,
  },
  electionsByCurrentYear: [],
  upcomingElections: [],
  currentRequestId: null,
  isMenuOpen: false,
  isPrivacyOptInModalActive: false,
  isSimulatedStudentSite: false,
  selectedState: '',
  voterType: 'all-voters',
  voterIsRegistered: '',
  voterFWAB: false,
  voterTimeCrunch: '',
  leo: {},
  siteMedia: {},
  nextElection: {},
  lastElection: {},
  deadlineElection: {},
  phones: {
    'DaPhone': { 'countryIso' : '',
             'dialCode': '',
             'internationalNumber': '',
             'nationalNumber': ''
    },
    'DaMobile': { 'countryIso' : '',
             'dialCode': '',
             'internationalNumber': '',
             'nationalNumber': ''
    },
    'fax': { 'countryIso' : '',
             'dialCode': '',
             'internationalNumber': '',
             'nationalNumber': ''
    },
    'tel': { 'countryIso' : '',
             'dialCode': '',
             'internationalNumber': '',
             'nationalNumber': ''
    },
  },
  votingState: {
    'selectedState': '',
    'isRegistered': '',
    'voterType': ''
  },
  butterA11yDictionary: null,
  butterBanners: null,
  butterDAGlobalPresidentialPrimary: null,
  butterDictionary: null,
  butterFaqCategories: null,
  butterFaqQuestions: null,
  butterLeos: null,
  butterSiteInfo: null,
  butterStateElections: null,
  butterStateVotingRules: null,
  butterStateVotingRulesFieldDescriptions: null,
  butterVFAGraphics: null,
  butterVFAParameters: null,
  butterVolunteerData: null,
  butterVoterAssistance: null,
  showCodeFragmentMark: false,
  showDictionaryKeys: false,
  showSandboxWarning: true,
  showTestData: false
})

export const strict = false

export const mutations = {
  RESTORE_MUTATION(state, payload) {
    vuexLocal.RESTORE_MUTATION(state, payload)
  },
  addElectionsOnCurrentYear(state, data) {
    state.electionsByCurrentYear = data
  },
  addUpcomingElections(state, data) {
    state.upcomingElections = data
  },
  clearVoterData(state) {
    state.selectedState = ''
    state.voterType = 'all-voters'
    state.voterIsRegistered = ''
    state.voterFWAB = false
    state.voterTimeCrunch = ''
    state.leo = {}
    state.phones = {
      'DaPhone': { 'countryIso' : '',
              'dialCode': '',
              'internationalNumber': '',
              'nationalNumber': ''
      },
      'DaMobile': { 'countryIso' : '',
              'dialCode': '',
              'internationalNumber': '',
              'nationalNumber': ''
      },
      'fax': { 'countryIso' : '',
               'dialCode': '',
               'internationalNumber': '',
               'nationalNumber': ''
      },
      'tel': { 'countryIso' : '',
               'dialCode': '',
               'internationalNumber': '',
               'nationalNumber': ''
      }
    }
  },
  closeMenu(state) {
    state.isMenuOpen = false
  },
  saveCookieConsent(state, cookieConsent) {
    state.cookieConsent = cookieConsent
  },
  saveSelectedState(state, selectedState) {
    state.selectedState = selectedState
  },
  saveVoterType(state, voterType) {
    state.voterType = voterType
  },
  saveVoterIsRegistered(state, voterIsRegistered) {
    state.voterIsRegistered = voterIsRegistered
  },
  saveVoterFWAB(state, voterFWAB) {
    state.voterFWAB = voterFWAB
  },
  saveVoterTimeCrunch(state, voterTimeCrunch) {
    state.voterTimeCrunch = voterTimeCrunch
  },
  saveLeo(state, leo) {
    state.leo = leo
  },
  saveSiteMedia(state, siteMedia) {
    state.siteMedia = siteMedia
  },
  saveNextElection(state, nextElection) {
    state.nextElection = nextElection
  },
  saveLastElection(state, lastElection) {
    state.lastElection = lastElection
  },
  saveDeadlineElection(state, deadlineElection) {
    state.deadlineElection = deadlineElection
  },
  saveShowCodeFragmentMark(state, showCodeFragmentMark) {
    state.showCodeFragmentMark = showCodeFragmentMark
  },
  saveShowDictionaryKeys(state, showDictionaryKeys) {
    state.showDictionaryKeys = showDictionaryKeys
  },
  saveShowSandboxWarning(state, showSandboxWarning) {
    state.showSandboxWarning = showSandboxWarning
  },
  saveShowTestData(state, showTestData) {
    state.showTestData = showTestData
  },
  savePhones(state, phones) {
    state.phones = phones
  },
  saveIsSimulatedStudentSite(state, isSimulatedStudentSite) {
    state.isSimulatedStudentSite = isSimulatedStudentSite
  },
  saveButterA11yDictionary(state, data) {
    state.butterA11yDictionary = data
  },
  saveButterBanners(state, data) {
    state.butterBanners = data
  },
  saveButterDAGlobalPresidentialPrimary(state, data) {
    state.butterDAGlobalPresidentialPrimary = data
  },
  saveButterDictionary(state, data) {
    state.butterDictionary = data
  },
  saveButterLeos(state, data) {
    state.butterLeos = data
  },
  saveButterFaqCategories(state, data) {
    state.butterFaqCategories = data
  },
  saveButterFaqQuestions(state, data) {
    state.butterFaqQuestions = data
  },
  saveButterSiteInfo(state, data) {
    state.butterSiteInfo = data
  },
  saveButterStateElections(state, data) {
    state.butterStateElections = data
  },
  saveButterStateVotingRules(state, data) {
    state.butterStateVotingRules = data
  },
  saveButterStateVotingRulesFieldDescriptions(state, data) {
    state.butterStateVotingRulesFieldDescriptions = data
  },
  saveButterVFAGraphics(state, data) {
    state.butterVFAGraphics = data
  },
  saveButterVFAParameters(state, data) {
    state.butterVFAParameters = data
  },
  saveButterVolunteerData(state, data) {
    state.butterVolunteerData = data
  },
  saveButterVoterAssistance(state, data) {
    state.butterVoterAssistance = data
  },
  toggleNav(state) {
    state.isMenuOpen = !state.isMenuOpen
  },
  togglePrivacyModalActiveState(state, newBool) {
    state.isPrivacyOptInModalActive =
      newBool === false || newBool ? newBool : !state.isPrivacyOptInModalActive
  },
}

export const actions = {
  toasty() {
    Toast.open(`Your age is: toast`)
  },

  async retrieveButterCollectionsAsync({ commit }) {
    let promiseArray = [
      butter.content
      .retrieve(['a11ydictionary'])
      .then((res) => {
        commit('saveButterA11yDictionary', res.data.data['a11ydictionary'])
        // console.log('retrieved: a11ydictionary')
      })
      .catch((err) => {
        console.log('error retrieving: a11ydictionary  '+err.message)
      }),

      butter.content
      .retrieve(['banners'])
      .then((res) => {
        commit('saveButterBanners', res.data.data['banners'])
        // console.log('retrieved: banners')
      })
      .catch((err) => {
        console.log('error retrieving: banners  '+err.message)
      }),

      butter.content
        .retrieve(['dictionary'])
        .then((res) => {
          commit('saveButterDictionary', res.data.data.dictionary)
          // console.log('retrieved: dictionary')
        })
        .catch((err) => {
          console.log('error retrieving: dictionary  '+err.message)
        }),

      butter.content
        .retrieve(['faqcategories'])
        .then((res) => {
          commit('saveButterFaqCategories', res.data.data.faqcategories)
          // console.log('retrieved: faqcategories')
        })
        .catch((err) => {
          console.log('error retrieving: faqcategories  '+err.message)
        }),

      butter.content
        .retrieve(['faqquestions'])
        .then((res) => {
          commit('saveButterFaqQuestions', res.data.data.faqquestions)
          // console.log('retrieved: faqquestions')
        })
        .catch((err) => {
          console.log('error retrieving: faqquestions  '+err.message)
        }),

      butter.content
        .retrieve(['local_election_officials-revisions'])
        .then((res) => {
          let leos = res.data.data['local_election_officials-revisions']
          // for filtering out fake states
          leos = leos.filter(x => STATE_ISO_CODE.includes(x.s_state.slice(0,2).toUpperCase()))
          commit('saveButterLeos', leos)
          // commit('saveButterLeos', res.data.data['local_election_officials-revisions'])
          // console.log('retrieved: local_election_officials-revisions')
        })
        .catch((err) => {
          console.log('error retrieving: local_election_officials-revisions  '+err.message)
        }),

      butter.content
        .retrieve(['site_info'])
        .then((res) => {
          commit('saveButterSiteInfo', res.data.data.site_info)
          // console.log('retrieved: site_info')
        })
        .catch((err) => {
          console.log('error retrieving: site_info  '+err.message)
        }),

      butter.content
        .retrieve(['state_elections'])
        .then((res) => {
          let elections = res.data.data.state_elections
          // for filtering out fake states
          elections = elections.filter(x => STATE_ISO_CODE.includes(x.stateid.slice(0,2)))
          commit('saveButterStateElections', elections)
          // console.log('retrieved: state_elections')
        })
        .catch((err) => {
          console.log('error retrieving: state_elections  '+err.message)
        }),

      butter.content
        .retrieve(['state_voting_rules'])
        .then((res) => {
          let voting_rules = res.data.data.state_voting_rules
          // for filtering out fake states
          voting_rules = voting_rules.filter(x => STATE_ISO_CODE.includes(x.stateid.slice(0,2)))
          commit('saveButterStateVotingRules', voting_rules)
          // console.log('retrieved: state_voting_rules')
        })
        .catch((err) => {
          console.log('error retrieving: state_voting_rules  '+err.message)
        }),

      butter.content
        .retrieve(['state_voting_rules_field_descriptions'])
        .then((res) => {
          commit('saveButterStateVotingRulesFieldDescriptions', res.data.data.state_voting_rules_field_descriptions)
          // console.log('retrieved: state_voting_rules_field_descriptions')
        })
        .catch((err) => {
          console.log('error retrieving: state_voting_rules_field_descriptions  '+err.message)
        }),

      butter.content
        .retrieve(['vfa_graphics'])
        .then((res) => {
          commit('saveButterVFAGraphics', res.data.data.vfa_graphics[0])
          // console.log('retrieved: vfa_graphics')
        })
        .catch((err) => {
          console.log('error retrieving: vfa_graphics  '+err.message)
        }),
        
      butter.content
        .retrieve(['vfa_parameters'])
        .then((res) => {
          commit('saveButterVFAParameters', res.data.data.vfa_parameters[0])
          // console.log('retrieved: vfa_parameters')
        })
        .catch((err) => {
          console.log('error retrieving: vfa_parameters  '+err.message)
        }),

      butter.content
        .retrieve(['volunteer'])
        .then((res) => {
          if (process.env.stage!=='prod') {
            commit('saveButterVolunteerData', res.data.data.volunteer)
            // console.log('retrieved: volunteer')
          }
        })
        .catch((err) => {
          console.log('error retrieving: volunteer  '+err.message)
        }),

        butter.content
        .retrieve(['voter_assistance'])
        .then((res) => {
          commit('saveButterVoterAssistance', res.data.data.voter_assistance)
          // console.log('retrieved: voter_assistance')
        })
        .catch((err) => {
          console.log('error retrieving: voter_assistance  '+err.message)
        }),
    ]

    if (process.env.isStudentSite !== 'true') {
      let promiseGpp =
        butter.content
          .retrieve(['da_global_presidential_primary'])
          .then((res) => {
            commit('saveButterDAGlobalPresidentialPrimary', res.data.data.da_global_presidential_primary)
            // console.log('retrieved: da_global_presidential_primary')
          })
          .catch((err) => {
            console.log('error retrieving: da_global_presidential_primary  '+err.message)
        })
      promiseArray.push(promiseGpp)
    }

    await Promise.all(promiseArray)
  }
}
