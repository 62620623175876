import axios from 'axios'

const jwtDecode = require('jwt-decode')

export const state = () => ({
  idToken: null,
  IdentityId: null,
  user: {
    language: 'en',
    country: 'US',
  },
  session: {
    ip: null,
    loc: null,
    city: null,
    region: null,
    country: null
  },
  device: {
    type: null,
    orientation: null,
    os: null
  }
})

export const getters = {
  userCountry: state => {
    return state.session.country
  }
}

export const mutations = {
  updateIdToken (state, idToken) {
    state.idToken = idToken
  },
  updateSessionGeo (state, geo) {
    state.session = geo
  },
  updateDevice (state, device) {
    state.device = Object.assign({}, state.device, device)
  },
  updateUser (state, userObj) {
    state.user = Object.assign({}, state.user, userObj)
  },
  updateIdentityId (state, id) {
    state.IdentityId = id
  }
}

export const actions = {
  async getSessionGeo ({commit, state, dispatch}) {
    if (!state.session.country) {
      let res = await axios.get('https://ipinfo.io/geo?token=3a36433b09c590')
      commit('updateSessionGeo', res.data)
      if (res.data.country) {
        dispatch('data/updateCountryData', res.data.country.toUpperCase(), {root: true})
      }
    }
  },
  async getUser ({commit, state, dispatch}) {
    await dispatch('getSessionGeo')
    commit('updateUser', {country: state.user.country !== 'US' && state.user.country ? state.user.country : state.session.country})
  },
  async setSession({state, rootState, commit, dispatch, app}) {
    if (state.redirectPath) {
      dispatch('redirect', state.redirectPath)
    }
    let newRequest = {}
    if (state.session && state.session.country) {
      newRequest.abrAdr = {countryiso: state.user.country || state.session.country}
    }
    commit('requests/update', newRequest, {root: true})
  },
  /**
   * 2023-01-01 John Yee
   * We need "clearData" and "clearVoterData" to clear voter data at selected places in the browsing session
   * e.g. at the end of an FPCA request session in the dashboard
   * e.g. when the voter starts a request session from a non-landing-page page
   * 
   * "clearVoterData" replaces "logout", which was used in the legacy VFA code
   */
  clearData ({ commit, dispatch }) {
    commit('updateIdToken', null)
    commit('updateUser', {
      country: null,
    })
    commit('updateSessionGeo', {
      city: null,
      country: null,
      ip: null,
      loc: null,
      region: null
    })
    commit('requests/clearRequests', null, { root: true })
    dispatch('getUser')
  },
  async clearVoterData ({ dispatch }) {
    this.app.$cookie.delete('vfaOptIn')
    dispatch('clearData')
  }
}
