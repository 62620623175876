/**
 * 2022-11-06 John Yee
 * A substitute for i18n $t
 * Does not use the language JSON files in lang/ That is, At does not actually do any translation.
 * 
 * Required prop "s" ("source") holds the source string.  This string must be in the target language.
 * 
 * Optional prop "p" ("parameters") holds an object that contains values to replace tokens embedded in the text.
 *  Format of tokens in the source text: "{" + token_name + "}"
 *  This is similar to the format used by $t
 *  The values must be in the target language.
 * 
 * Optional prop "as" holds the HTML tag.
 */
export default {
  name: 'At',
  props: {
    s: {
      type: String,
      require: true,
    },
    p: {
      type: Object,
      default: () => ({})
    },
    as: {
      type: String,
      default: 'span',
    },
  },
  render(h) {
    let el = this.as
    let str = this.s
    if (str) {
      str = this.s.replace(/{\s+/gi, '{').replace(/\s+}/gi, '}')
      let keys = Object.keys(this.p)
      if (keys.length) {
        for (let ii=0; ii<keys.length; ii++) {
          str = str.replace(new RegExp('{'+keys[ii]+'}', 'gi'), this.p[keys[ii]])
        }
      }
      str = str.replace(/\\n/gi, '<br>').replace(/\\t/gi, '&emsp;') /* .trim() // may want to allow Butter to pre-/ap-pend spaces */
    }

    /**
     * ensure block-level HTML elements are inserted inside block-level element
     * 
     * here is a list of block-level elements...
        <address>
        <article>
        <aside>
        <blockquote>
        <canvas>
        <dd>
        <div>
        <dl>
        <dt>
        <fieldset>
        <figcaption>
        <figure>
        <footer>
        <form>
        <h1>-<h6>
        <header>
        <hr>
        <li>
        <main>
        <nav>
        <noscript>
        <ol>
        <p>
        <pre>
        <section>
        <table>
        <tfoot>
        <ul>
        <video>
     *
     */
    if (el==='span') {
      if (
            str.includes('<div')
         || str.includes('<h1')
         || str.includes('<h2')
         || str.includes('<h3')
         || str.includes('<h4')
         || str.includes('<h5') 
         || str.includes('<h5')
         || str.includes('<hr')
         || str.includes('<p')
         || str.includes('<pre')
        )
      {
        el = 'div'
      }
    }
    return h(el, {
      domProps: { innerHTML: str },
    })
  },
}
