export default function ({ route, params, store, redirect }) {
  let current = store.getters['requests/getCurrent']
  let isRequiredStateId = false
  if (current && current.votAdr && current.votAdr.S) {
    const stateVotingRules = store.state.butterStateVotingRules ? (store.state.butterStateVotingRules.find(x => x.stateid.slice(0,2).toLowerCase()===current.votAdr.S.toLowerCase()) || null) : null
    isRequiredStateId = stateVotingRules ? (stateVotingRules.id ? true : false) : false
  }

  if ( (params.stage === 'voting-information' ||
        params.stage === 'id-and-contact-information' ||
        route.name.indexOf('review') > -1 ||
        route.name.indexOf('sign-submit-nextsteps') > -1) &&
       (!current ||
        !current.firstName ||
        !current.lastName ||
        !current.abrAdr ||
        !current.abrAdr.formatted ||
        current.abrAdr.formatted.length === 0)
  ) {
    return redirect('/request/your-information')
  } else if ( (params.stage === 'id-and-contact-information' ||
               route.name.indexOf('review') > -1 ||
               route.name.indexOf('sign-submit-nextsteps') > -1) &&
              (!current.votAdr ||
               !current.votAdr.A ||
               !current.votAdr.C ||
               !current.votAdr.S ||
               !current.votAdr.Z ||
               !current.leo.a1 ||
               !current.voterClass ||
               !current.recBallot)
  ) {
    return redirect('/request/voting-information')
  } else if ( (route.name.indexOf('review') > -1 ||
               route.name.indexOf('sign-submit-nextsteps') > -1) &&
              (!current.identification &&
                isRequiredStateId)
  ) {
    return redirect('/request/id-and-contact-information')
  }
}
