//
//
//

export default {
  mounted () {
    if (process.browser) {
      window.scrollTo(0, 0)
    }
  }
}
