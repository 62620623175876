import {
  AsYouType,
  formatNumber,
  isValidNumber,
  getNumberType
} from 'libphonenumber-js/custom'
import Mailcheck from 'mailcheck'
import { helpers } from 'vuelidate/lib/validators'

const md = require('~/data/phoneMeta/bareMeta.json')
const sureThing = promise =>
  promise
    .then(result => ({ ok: true, result }))
    .catch(error => Promise.resolve({ ok: false, error }));

export const state = () => ({
  postal: {},
  stateRules: {},
  elections: [],
  phoneMetadata: md,
  countries: require('~/data/countries/countriesWithPhoneExamples.json'),
  phoneExamples: require('libphonenumber-js/examples.mobile.json'),
  phone: '',
  fax: '',
  email: ''
})

function getLabel (type, countryData) {
  switch (type) {
    case 'A':
      return 'Street Address 1'
    case 'C':
      return countryData.locality_name_type || 'City'
    case 'D':
      return countryData.sublocality_name_type || 'Neighborhood'
    case 'S':
      return countryData.state_name_type || 'Province'
    case 'Z':
      return countryData.zip_name_type || 'Postal Code'
    case 'X':
      return countryData.sortingcode_name_type || 'Sorting Code'
    default:
      return ''
  }
}

export const getters = {
  phoneMetadataHasCountry: state => countryCode => {
    return (
      Boolean(countryCode) &&
      Boolean(state.phoneMetadata.countries[countryCode])
    )
  },
  postalMetadataHasCountry: state => countryCode => {
    return Boolean(countryCode && state.postal[countryCode.toUpperCase()])
  },
  postalDataForCountry: state => countryCode => {
    return countryCode ? state.postal[countryCode.toUpperCase()] : null
  },
  votingStates: (state, getters) => {
    let usData = getters.postalMetadataHasCountry('US')
      ? state.postal.US
      : null
    return usData
      ? usData.sub_names.split('~').map((state, i) => ({
        name: state,
        key: usData.sub_keys.split('~')[i]
      }))
      : []
  },
  addressPartRequired: (state, getters) => (countryCode, addressPart) => {
    return getters.postalDataForCountry(countryCode)
      ? getters.postalDataForCountry(countryCode).require.includes(addressPart)
      : false
  },
  validZip: (state, getters) => value => {
    if (!value) return true
    // console.log('this', value)
    let countryData = getters.postalMetadataHasCountry(value.countryiso || 'US')
      ? getters.postalDataForCountry(value.countryiso || 'US')
      : {}
    let countryZipRegex =
      value && countryData.zip ? new RegExp(countryData.zip) : null
    let countryZipEx =
      countryZipRegex && countryData.zipex
        ? countryData.zipex.split(',').join(' or ')
        : null
    let stateZipRegex =
      countryZipRegex &&
      value.S &&
      countryData.sub_zips &&
      countryData.sub_names.split('~').includes(value.S)
        ? new RegExp(
          countryData.sub_zips.split('~')[
            countryData.sub_names
              .split('~')
              .findIndex(x => x.toLowerCase() === value.S.toLowerCase())
          ]
        )
        : null
    let stateZipEx =
      stateZipRegex &&
      countryData.sub_zipexs &&
      countryData.sub_names.split('~').includes(value.S)
        ? countryData.sub_zipexs
          .split('~')[
            countryData.sub_names
              .split('~')
              .findIndex(x => x.toLowerCase() === value.S.toLowerCase())
          ].split('~')
          .join(' or ')
        : null
    return value &&
      getters.postalMetadataHasCountry(value.countryiso || 'US') &&
      getters.postalDataForCountry(value.countryiso || 'US').zip
      ? helpers.withParams(
        {
          type: 'validZip',
          for: stateZipRegex
            ? value.S
            : countryZipRegex
              ? value.countryiso || 'US'
              : null,
          zipex: stateZipEx || countryZipEx
        },
        helpers.regex('validZip', stateZipRegex || countryZipRegex)
      )
      : true
  },
  countriesWithPostalData: state =>
    state.countries.filter(country => !!state.postal[country.code]),
  countriesWithPostalData2: state =>
    state.countries
      .filter(country => !!state.postal[country.code])
      .map(x => state.postal[x.code])
      .map(countryData => {
        let parsedFormat =
          !countryData || (!countryData.lfmt && !countryData.fmt)
            ? {}
            : (countryData.lfmt || countryData.fmt) // use latinized format if available
              .split(/%n/) // split by line
              .map(x =>
                x
                  .replace(/([^%N|%O|%A|%D|%C|%S|%X|%Z])/g, '')
                  .split(/\s|%/)
                  .filter(t => /^[NOADCSXZ]$/.test(t)) // only deal with address part tokens
                  .map(y => {
                    let addressPartObject = {
                      type: y,
                      label: getLabel(y, countryData),
                      length: x.replace(
                        /([^%N|%O|%A|%D|%C|%S|%X|%Z]|[\s]|[%])/g,
                        ''
                      ).length,
                      required: false
                    }
                    if (
                      countryData.require &&
                        countryData.require.includes(addressPartObject.type)
                    ) {
                      addressPartObject.required = true
                    }
                    if (
                      !countryData.require &&
                        /A|C/.test(addressPartObject.type)
                    ) {
                      addressPartObject.required = true
                    }
                    if (addressPartObject.type === 'Z') {
                      addressPartObject.example = countryData.zipex
                        ? countryData.zipex.split(',')[0]
                        : ''
                      addressPartObject.regex = countryData.zip || ''
                    }
                    if (
                      addressPartObject.type === 'S' &&
                        countryData.sub_keys
                    ) {
                      addressPartObject.options = countryData.sub_keys
                        .split('~')
                        .map((key, i) => ({
                          key: key,
                          iso: countryData.sub_isoids
                            ? countryData.sub_isoids.split('~')[i]
                            : undefined,
                          name: countryData.sub_names
                            ? countryData.sub_names.split('~')[i]
                            : undefined,
                          zipex: countryData.sub_zipexs
                            ? countryData.sub_zipexs.split('~')[i]
                            : undefined,
                          zip: countryData.sub_zips
                            ? countryData.sub_zips.split('~')[i]
                            : undefined
                        }))
                    }
                    return addressPartObject
                  })
              )
              .reduce((acc, cur) => acc.concat(cur), []) // flatten array
        return {
          format: parsedFormat,
          ...countryData
        }
      }),
  hasValidPhonePrefix: (state, getters) => number => {
    if (number && number.length > 1 && number.charAt(0) === '+') {
      let res = false;
      [2, 3, 4].forEach(n => {
        if (state.phoneMetadata.country_calling_codes[number.slice(1, n)]) {
          res = true
        }
      })
      return res
    } else return false
  },
  phoneMetadataHasAllCountriesForPrefix: (state, getters) => number => {
    if (number && number.length > 1 && number.charAt(0) === '+') {
      return [2, 3, 4]
        .map(x =>
          x <= number.length
            ? state.phoneMetadata.country_calling_codes[number.slice(1, x)]
            : undefined
        )
        .reduce((acc, val) => (val ? acc.concat(val) : acc), [])
        .every(country => getters.phoneMetadataHasCountry(country))
    } else return 'other result'
  },
  formattedNumber: state => (number, countryCode) => {
    let formatted = new AsYouType(countryCode, state.phoneMetadata)
    let text = formatted.input(number)
    return { text, formatted }
  },
  getPhoneFormatInfo: state => (number, countryCode) => {
    let formatted = new AsYouType(countryCode, state.phoneMetadata)
    formatted.input(number)
    return formatted
  },
  getPhoneIntFormat: state => (phone, country) =>
    formatNumber({ country, phone }, 'International', state.phoneMetadata),
  isValidNumber: (state, getters) => (phone, country) => {
    const BY_PASS = true
    if (BY_PASS) {
      return true
    }
    if (/^\+84/.test(phone) || /^\+63/.test(phone)) {
      return true
    }
    if (
      phone &&
      (getters.phoneMetadataHasCountry(country) ||
        getters.phoneMetadataHasAllCountriesForPrefix(phone))
    ) {
      return country
        ? isValidNumber(phone, country, state.phoneMetadata)
        : isValidNumber(phone, state.phoneMetadata)
    } else return false
  },
  isMobileNumber: (state, getters) => (phone, country) => {
    if (
      phone &&
      (getters.phoneMetadataHasCountry(country) ||
        getters.phoneMetadataHasAllCountriesForPrefix(phone))
    ) {
      return getNumberType(phone, country, state.phoneMetadata)
    }
  },
  isValidEmail: state => email =>
    /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(
      email
    ),
  mailCheck: state => (email, commonFullDomains, commonTopLevelDomains) =>
    Mailcheck.run({
      domains: commonFullDomains,
      topLevelDomains: commonTopLevelDomains,
      email: email,
      suggested: function(suggestion) {
        // console.log(suggestion)
        let s = suggestion.full
        s =
          s.split('@')[1].split('.').length > 1 &&
          s.split('@')[1].split('.')[s.split('@')[1].split('.').length - 1]
            .length > 1
            ? s
            : ''
        return s
      },
      empty: function() {
        return ''
      }
    }),
}

export const mutations = {
  addCountryPhoneMetadata(state, payload) {
    this._vm.$set(state.phoneMetadata.countries, payload.country, payload.data)
  },
  addCountryPostalMetadata(state, payload) {
    state.postal = { ...state.postal, ...payload }
  },
}

export const actions = {
  async updateCountryData({ state, commit, getters }, country) {
    country = country ? country.toUpperCase() : null
    if (
      country &&
      (!getters.postalMetadataHasCountry(country) ||
        !getters.phoneMetadataHasCountry(country)) &&
      process.browser
    ) {
      let thisPostalData = await import(
        `~/localization/${country ? country.toUpperCase() : 'US'}.json`
      );
      commit('addCountryPostalMetadata', { [country]: thisPostalData })
      if (
        Object.values(state.phoneMetadata.country_calling_codes)
          .reduce((acc, cur) => acc.concat(cur), [])
          .includes(country)
      ) {
        let thisPhoneData = await import(`~/data/phoneMeta/${country}.json`);
        commit('addCountryPhoneMetadata', {
          country: country,
          data: thisPhoneData
        });
      }
      return getters.postalDataForCountry(country)
    } else {
      return getters.postalDataForCountry(country)
    }
  },
  async getCountryIsoFromPhonePrefix({ state, dispatch }, number) {
    if (number && number.length > 1 && number.charAt(0) === '+') {
      let callingCodes = state.phoneMetadata.country_calling_codes;
      for (let i = 1; i < number.length && i < 5; i++) {
        let thisCode = number.slice(1, i);
        if (callingCodes[thisCode]) {
          return Promise.all(
            callingCodes[thisCode].map(code =>
              sureThing(dispatch('updateCountryData', code))
            )
          );
        }
      }
    } else return { ok: true, result: 'no country codes found' };
  },
}
